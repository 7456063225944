html,
body {
    height: 100%;
}
body {
    display: flex;
    flex-direction: column;
    font-family: Roboto, sans-serif;

    margin: 0;
    padding: 0;
}

body.noscroll, body.install-noscroll {
    overflow: hidden;
}

div#root {
    width: 100%;
    height: 100%;
}

@media all and (max-width: 64em) {
    div#root {
        height: auto;
    }

    div#root.noscroll {
        overflow: hidden;
    }

    div#root.login-page {
        height: 100%;
    }
}

@media print {
    body {
        display: block;
    }

    div#root {
        display: block;
    }
}

/* HTML component stying */

input.standalone {
    font-size: 1.3em;
    padding: 10px 30px;
    text-align: center;
    border: 1px solid #d5d4d5;
    border-radius: 8px;
}

button {
    cursor: pointer;
    font-size: 1.2em;
    padding: 10px 30px;
}

button.primary {
    color: white;
    background-color: #205099;
}
button.primary:hover {
    background-color: #3c6199;
}

button.rounded {
    border-radius: 22px;
    border: none;
}

section {
    margin: 30px;
    padding: 30px;
    border: none;
    border-radius: 8px;
}

section > h1,
section > h2,
section > h3 {
    margin: 0;
}

section.warning {
    background-color: #fef48a;
}

/* CSS Transition Group animations */
.customer-enter {
    opacity: 0.01;
}

.customer-enter.customer-enter-active {
    opacity: 1;
    transition: 500ms;
}

.customer-exit {
    opacity: 1;
    transition: opacity 300ms ease, height 400ms ease 300ms;
}

.customer-exit.customer-exit-active {
    opacity: 0.01;
    height: 0px;
}
